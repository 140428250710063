import React from "react";

const TermsAndConditions = () => {

    return (
        <div className="policies-text">
            <p>Please read these Terms and Conditions carefully before using our website as these apply to your use of our website. If you do not agree to these Terms and Conditions then please do not use this website.</p>
            
            <h2>About us</h2>
            <p>This website is owned and operated by Galactic Marketing Limited, a company registered in England and Wales (company number 10922212) with its registered office at 49 Station Road, Polegate, England, BN26 6EA. Galactic Marketing Limited is licenced by the Information Commissioner's Office, (registration number ZA480834).</p>
            
            <h2>Intellectual property rights</h2>
            <p>The copyright and material on this website is owned by Galactic Marketing Limited and is protected by the copyright laws of the United Kingdom.</p>
            <p>All logos, names, images and information used on our website are the property of Galactic Marketing Limited.</p>
            <p>You may republish, reproduce, download or temporarily store extracts from our website for your personal information or when you use our products and services. You may not republish, retransmit, redistribute or otherwise make such information on pages available to any other party or available on any website, bulletin board or on-line service of your own or of any other party or make the same available in hard copy or on any other media without our express prior consent.</p>

            <h2>Disclaimers</h2>
            <p>All information provided on our website is subject to change without notice. Changes may be made to the Terms and Conditions at any time without notice by updating this posting. Any revised term shall take effect as at the date when the change is made to the website. You agree to review the Terms and Conditions regularly and your continued access to or use of the website will mean that you agree to any changes.</p>
            <p>We cannot guarantee that any files, documents or other data you download from our website will be free of viruses or contamination or destructive features.</p>
            <p>We disclaim all warranties, express or implied, including any warranties of accuracy, non-infringement, merchantability and fitness for a particular purpose.</p>
            <p>We disclaim any and all liability for the acts, omissions and conduct of any third parties in connection with or related to your use of our website. You assume total responsibility for your use of our website and any linked websites.</p>

            <h2>Liability</h2>
            <p>We take all reasonable steps to ensure the accuracy, currency, availability, correctness and completeness of the information contained on the website. Information is provided on an "as is", "as available" basis and we do not give or make any warranty or representation of any kind whether express or implied. To the extent permitted by law, we other member of our group of companies and third parties connection to us hereby expressly exclude:</p>
            <ul>
                <li>All conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity;</li>
                <li>Any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with our website or in connection with the use, inability of use or results or the use of our website, any websites linked to it and any materials posted on it, whether caused by tort, (including negligence), breach of contract or otherwise, even if foreseeable, provided that this condition shall not prevent claims for loss of or damage to your tangible property or any other claims for direct financial loss that are not excluded by any of the categories set out above.</li>
                <li>This does not affect our liability for death or personal injury arising from our negligence not our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which cannot be excluded or limited under applicable law.</li>
            </ul>

            <h2>Indemnity</h2>
            <p>By using this website you agree to defend, indemnify and hold us harmless from and against any and all losses, claims, damages, costs and expenses (including reasonable professional fees) that we may be obliged to pay arising or resulting from your use of the website, the content or your breach of these terms.</p>
            <p>We reserve the right to assume or participate at your expense in the investigation, settlement and defence of any such action or claim.</p>
            <p>You will also indemnify and hold us harmless from and against any claims brought by third parties arising out of your use of the information accessed through your use of this site or related website(s).</p>

            <h2>Complaints</h2>
            <p>
                We endeavour to provide our customers with the highest standards of service and care but if you wish to make a formal complaint please contact us:<br/>
                Email: <a href="mailto:compliance@galacticmarketing.co.uk">compliance@galacticmarketing.co.uk</a> <br/>
                Write to: Galactic Marketing Limited, 49 Station Road, Polegate, England, BN26 6EA
            </p>

            <h2>Third Party Websites</h2>
            <p>Links displayed on this website may lead to other websites not under our control and we accept no liability for the content or availability of any linked site which is not operated by us or any link contained in a linked site not operated by us.</p>
            <p>Please be aware that other websites linked to our website will be governed by separate terms and conditions, including privacy policies. You should read the terms and conditions and privacy policies of those websites before using them and direct any questions or comments about the linked website's contents to the relevant website provider.</p>
            <p>We may receive a commission from the third-party website provider for the introduction made via this website.</p>
            <p>With our prior written consent you may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>

            <h2>Cookies</h2>
            <p>This website uses cookies. Cookies are small text files which contain small amounts of information which are downloaded on to the browser or hard drive of your computer, mobile or other device.</p>
            <p>Cookies enhance user experience by remembering user preferences, and letting users navigate between pages efficiently.</p>
            <p>You may prevent the use of cookies by configuring your web browser accordingly but this may impact of the website's functionality. </p>

            <h2>Compliance</h2>
            <p>This website may only be used for lawful purposes and in a lawful manner. You agree to comply with all applicable laws, statutes and regulations regarding its use. You agree not to upload or transmit through your usage of this website any computer viruses or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer. You further agree not to upload or transmit through the usage of this website any material which is defamatory, offensive or of an obscene character.</p>

            <h2>Miscellaneous</h2>
            <p>These website terms and conditions form our entire agreement with you and any and all other written or oral agreements or understandings previously existing between us relating to such use are hereby superseded and cancelled.</p>
            <p>Any failure by us to exercise any rights or enforce any of these Terms and Conditions shall not constitute a waiver of such rights or terms.</p>
            <p>If any provision of these Terms and Conditions or their application in a particular circumstance is held to be invalid or unenforceable to any extent, the remainder of these Terms or the application of such provision in other circumstances shall not be affected thereby and each provision hereof shall be valid and enforced to the fullest extent permitted by law.</p>

            <h2>Jurisdiction</h2>
            <p>This website and its terms are governed by and in accordance with the law of England and Wales and whose Courts will have exclusive jurisdiction in respect of any dispute which may arise.</p>

            <h2>How to contact us</h2>
            <p>
                If you have any questions then please contact us:<br/>
                Email: <a href="mailto:compliance@galacticmarketing.co.uk">compliance@galacticmarketing.co.uk</a> <br/>
                Write to: Galactic Marketing Limited, 49 Station Road, Polegate, England, BN26 6EA
            </p>
        </div>
    );

};

export default TermsAndConditions;