import React from "react";
import Particles from "react-tsparticles";
import particlesConfig from "../ParticlesSettings";

const ParticlesBackground = () => {
    return (
        <div className="particles-background">
            <Particles className="particles" params={particlesConfig}/>
        </div>
    );
}

export default ParticlesBackground;