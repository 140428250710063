import React from "react";
import { Link } from "react-router-dom";
import ParticlesBackground from './ParticlesBackground';

const Home = () => {

    return (
        <div className="App">
            <ParticlesBackground />
            <header className="App-header">
                <p className='splash'>Galactic Marketing Ltd.</p>
                <p>
                    <Link className='App-link' to="/privacy-policy">Privacy Policy</Link>  
                    <span className='App-link'> | </span>  
                    <Link className='App-link' to="/terms-and-conditions">Terms and Conditions</Link>
                </p>
            </header>
        </div>
    )

};

export default Home