import React from "react";

const PrivacyPolicy = () => {

    return (
        <div className="policies-text">
            <h2>1. Who we are</h2>
            <p>We understand how important your privacy is to you and ensure any personal data you provide us with is treated with respect, is properly protected and handled in accordance with UK data protection rules and the General Data Protection Regulation (Regulation (EU) 2016/679) <strong>(GDPR)</strong>.</p>
            <p>This website is owned and operated by Galactic Marketing Limited, a company registered in England and Wales (company number 10922212) with its registered office at 49 Station Road, Polegate, England, BN26 6EA. Galactic Marketing Limited is also licenced by the Information Commissioner's Office, (registration number ZA480834).</p>
            <p>Contained within this statement and set out below are details of the type of personal data we may hold about you, our customer, how we obtain and process any personal data we may have and, most importantly, how we protect your privacy. This policy relates only to the personal data submitted or collected via this website.</p>
            <p>If you have any queries or concerns regarding how we use your personal data or any questions regarding any of the information provided in this statement, please contact our Data Protection Officer at <a href="mailto: compliance@galacticmarketing.co.uk">compliance@galacticmarketing.co.uk</a></p>

            <h2>2. General Data Protection Principles</h2>
            <p>Under the GDPR we are required to process the personal data you provide us with in a responsible manner. In accordance with the six principles below it must be:</p>
            <ul>
                <li>processed lawfully, fairly and in a transparent manner in relation to individuals;</li>
                <li>collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposed; further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes shall not be considered to be incompatible with the initial purposes;</li>
                <li>adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed;</li>
                <li>accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that is inaccurate, having regard to the purposes for which it is processed, are erased or rectified without delay;</li>
                <li>kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data is processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes subject to implementation of the appropriate technical and organisational measures required by the GDPR in order to safeguard the rights and freedoms of individuals;</li>
                <li>processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures.</li>
            </ul>

            <h2>3. Your Personal Data</h2>
            <h3>What Personal Data do we collect?</h3>
            <ul>
                <li>Personal data you have provided to us on this website;</li>
                <li>Registration on another site - you may have provided your personal data to a third party by filling in forms on their website, consented for the information to be passed to us and been re-directed to our website;</li>
                <li>In correspondence between us and to enable us to answer queries or address complaints; and</li>
                <li>Details of your visits to our sites and the resources you access (including, but not limited to, IP address, traffic data, location data, weblogs and other communication data). </li>
            </ul>
            <h3>Why do we collect your Personal Data?</h3>
            <p>We use information about you to:</p>
            <ul>
                <li>Communicate with you in connection with our services by email, SMS, telephone or post</li>
                <li>Contact you to offer products, services or offers you may be interested in </li>
                <li>Verify your personal information</li>
                <li>Conduct statistical analysis for our own internal processes and/or to detect, prevent and investigate actual and potential fraud and related activities</li>
                <li>Update this website to meet our customers' needs in the future and develop, improve and update our products</li>
                <li>Assist us in the administration and servicing of your account</li>
                <li>Notify you of any changes to our services</li>
                <li>Comply with any legal or regulatory obligations</li>
            </ul>
            <h3>Legal basis for collecting your personal data</h3>
            <p>We rely on the following legal bases to process your personal data under the UK Data Protection legislation and the General Data Protection Regulation (Regulation (EU) 2016/679) (GDPR):</p>
            <p>Contract: We process some of your data as a necessary part of performing our contractual obligations to you. </p>
            <p>Legal Obligation: There may be situations where we need to use your information to comply with legal and regulatory obligations or defend claims. </p>
            <p>Legitimate Interest: We process some of your data because it is in our legitimate interests to do so. Where this is the case, we have considered the impact that using your information may have on you and limited our use to only what is strictly necessary.</p>
            <p>Consent: Where you have provided us or a third-party partner with consent to receive marketing communications from us via email or SMS.  Customers are able to opt out of these communications by using the unsubscribe function in any communication we send.</p>
            <h3>How we use your Personal Data</h3>
            <p>Data protection, privacy and security are important to us, and we shall only use your personal information for specified purposes. The following are examples of such purposes. </p>
            <ul>
                <li>To help us to identify you when you contact us;</li>
                <li>To help us to identify accounts, services and/or products which you may have with us;</li>
                <li>To help us to administer any accounts, services and products we have provided you with;</li>
                <li>To allow us to carry out customer profiling to improve our websites and services; </li>
                <li>To help to prevent and detect fraud or loss;</li>
                <li>To enforce our Terms and Conditions.</li>
                <li>To send you marketing communications by email or SMS where you have given us, or one of our trusted third-party partners, your express consent to receive marketing from us.  The products, services and offers we may market include, but are not limited to: <br />Loan Comparison - Payday Loans/Short Term Credit - Loan Brokerage - Credit and Debit Cards - Credit Score Companies - Current Accounts - Guarantor Loans - Mobile Phones - Car Finance - Home Repairs - Debt Services - Utility Switching - Broadband - Online Retailers - Tax Rebate.</li>
            </ul>
            <h3>Who do we share your personal data with?</h3>
            <p>We may share the personal data you provide with:</p>
            <ul>
                <li>our group companies and affiliates;</li>
                <li>HMRC, legal and other regulatory authorities, including those who request your personal data or to report any potential or actual breach of applicable law or regulation;</li>
                <li>external professional advisers such as accountants, auditors, bankers, insurers and lawyers;</li>
                <li>law enforcement agencies, courts or other relevant party, to the extent necessary for the establishment, exercise or defence of legal rights;</li>
                <li>third parties where necessary for the purposes of prevention, investigation, detection or prosecution of criminal offences or the execution of criminal penalties;</li>
                <li>third parties which are considering or have decided to acquire some or all of our assets or shares (including in the event of a reorganisation, dissolution or liquidation);</li>
                <li>third parties operating plugins or content on our website which you choose to interact with</li>
                <li>A buyer or successor in the event of a sale or transfer of our business;</li>
                <li>Service providers - we may share your personal data with companies who assist us in providing our service to you such as telecommunication services (for email and SMS communication) and technology services for the operation and maintenance of our websites. Access to your personal data by these service providers is limited to the information reasonably required for them to perform their function. We take steps to help ensure that service providers keep your personal data confidential and comply with our privacy and security requirements.</li>
            </ul>
            <h3>How long do we keep your data for?</h3>
            <p>The amount of time we retain your data for depends on the basis it was provided by you to us:</p>
            <ul>
                <li>In order to provide you with a service we will be required to keep the data whilst we continue to provide you with a service for however long this may take us.</li>
                <li>If you request that we do not contact you for advertising and marketing reasons, then we still need to keep your application data as this reflects your preferences. The information we retain will be the minimum we are required to keep</li>
                <li>Data is kept relating to any transactions you may enter via our website. This data will be kept for a minimum of 6 years which will commence at the end of the relationship. We are required to do this to ensure that if any disputes or complaints are raised, then we have your data to enable us to address the complaints or disputes.</li>
                <li>In order to comply with our obligations under UK &amp; EU law.</li>
                <li>We will retain your personal data as advised above unless we have a legitimate reason for retaining it beyond those dates.</li>
            </ul>
            <h3>Where will your personal data be held?</h3>
            <p>Your personal data may be held within the European Economic Area which comes under the GDPR. Where personal data is transferred or stored outside of the European Economic Area we check whether the EU Commission has issued an adequacy decision, which is a confirmation from them that the personal data will be afforded the same level of protection as if it was within the European Economic Area.</p>
            <p>If there is not an adequacy decision in place, and to comply with GDPR requirements, we will ensure the transfer of personal data is protected by other means, including where explicit consent from the data subject has been received or through contractual obligations with relevant third parties regarding usage of the personal data.</p>
            <h3>Confidentiality &amp; security</h3>
            <p>Keeping your personal data secure and confidential is of tantamount importance to us. Due to this we have introduced rules, measures, specialist technology and implemented security policies to ensure that your data is protected.</p>
            <p>We use Secure Sockets Layer (SSL) encryption to secure your personal data and only provide your personal data to the staff who have to access it for the function of carrying out their duties. Whilst we make every attempt to protect your data there are still risks involved when inputting your data online and we need to make you aware of this.</p>
            <p>We can further confirm that all parties we share your personal data with are bound by contract to maintain the security and integrity of your personal data.</p>
            <p>Please be advised that, although Galactic Marketing Ltd takes all reasonable precautions to protect your data, no data storage or data transmission security is guaranteed. Accordingly, Galactic Marketing Ltd cannot, and does not, represent, warrant, or guarantee the complete security of any data storage or data transmission. You agree that your access to and use of this site and your use of Galactic Marketing Ltd's services, which necessarily includes data storage and data transmission, is carried out at your own risk.</p>
            
            <h2>4. Your Individual Rights</h2>
            <p>Subject to certain circumstances, you are able to exercise all of the rights below. Where you elect to exercise any of your rights please be aware we do not charge you a fee. However, if we receive excessive, repetitive or unfounded requests we are permitted to charge a reasonable fee.</p>
            <h3>Right to be informed</h3>
            <p>You have the right to be informed about the collection and use of your personal data. To be completely transparent with how we use your personal data we provide you with the following privacy information:</p>
            <ul>
                <li>The name and contact details of our organisation and the contact details of our Data Protection Officer</li>
                <li>The purposes and lawful basis for processing personal data</li>
                <li>The categories of personal data obtained</li>
                <li>The recipients or categories of recipients of the personal data</li>
                <li>The details of transfers of the personal data to any third countries or international organisations</li>
                <li>The retention periods for the personal data</li>
                <li>The rights available to individuals in respect of the processing</li>
                <li>The right to withdraw consent and the right to lodge a complaint with a supervisory authority</li>
                <li>The details of the existence of automated decision-making, including profiling.<br/>We regularly review, and where necessary, update our privacy information.</li>
            </ul>
            <h3>Right of access</h3>
            <p>You have the right to know who has access to your personal data, what it is being used for, where it's kept, and everything about your personal data as it passes through our data process. A 'Subject Access Request' (SAR) can be made requesting us to supply you with all reasonable information or documentation that we hold relating to your personal data. You can make a subject access request verbally or in writing. If you make your request verbally, we recommend you follow it up in writing to provide a clear trail of correspondence.</p>
            <p>Before providing any information we must verify the identity of the person making the request using 'reasonable means'. We will ask you for your first name, surname, date of birth, address, a utility bill (or similar) and the method by which you received marketing communications.</p>
            <p>Please email your SAR to our Data Protection Officer at <a href="mailto:compliance@galacticmarketing.co.uk">compliance@galacticmarketing.co.uk</a></p>
            <p>Alternatively, you can post your request to Galactic Marketing Limited, 49 Station Road, Polegate, England, BN26 6EA.</p>
            <p>Responses will be provided electronically in a clear format unless requested otherwise. We have one month to comply with this request but if the request is complex can extend the period for a further two months. You would be notified of the time limit when making the request.</p>
            <p>For more information on how you make a SAR please visit <a href="https://ico.org.uk/your-data-matters/your-right-to-get-copies-of-your-data/" target="_blank" rel="noreferrer">https://ico.org.uk/your-data-matters/your-right-to-get-copies-of-your-data/</a></p>
            <h3>Right to rectification</h3>
            <p>The GDPR includes a right for individuals to have inaccurate personal data rectified or completed if it is incomplete.</p>
            <p>It is important that the personal data you input on this website is accurate, complete and up to date.</p>
            <p>We will assume the personal data you provide is correct and provide our services on this basis. If fraudulent information is given then we may be alerted to this by a Fraud Prevention Agency. If you think you have mistakenly given us incorrect information, please contact us at <a href="mailto:compliance@galacticmarketing.co.uk">compliance@galacticmarketing.co.uk</a> and we will update it as soon as possible.</p>
            <p>Whilst we make every effort to ensure the personal data we hold about you is right, if you notice any errors in any communications received from us please send us an email detailing the changes to the email address above and we will ensure it is accurate.</p>
            <p>We will rectify the information within a month of receiving your request and inform all relevant third parties of the amendment. If the request is complex we can extend the period for a further two months.</p>
            <h3>Right to erasure</h3>
            <p>You have the right to erasure which is also known as a 'request to be forgotten'. This equates to asking for any personal data you have supplied to be removed from our systems where there is no reason to keep processing it.</p>
            <p>This right can be exercised under the following circumstances:</p>
            <ul>
                <li>Where the individual withdraws consent</li>
                <li>Where the personal data is no longer necessary in relation to the purpose for which it was originally collected/processed.</li>
                <li>The personal data was unlawfully processed (i.e. otherwise in breach of the GDPR).</li>
                <li>When the individual objects to the processing and there is no overriding legitimate interest for continuing processing.</li>
                <li>The personal data has to be erased in order to comply with a legal obligation.</li>
                <li>The personal data is processed in relation to the offer of information society services to a child.</li>
            </ul>
            <p>We will suppress and remove all data held, upon request. Our marketing partners will immediately be informed of the request and will follow the same actions. We will however keep the communication of the 'request to be forgotten' for our records. Where we cannot remove all your personal data we will notify you.</p>
            <p>Grounds on which we can legally refuse a request are:</p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>The exercise or defence of legal claims</li>
                <li>To exercise the right of freedom of expression and information</li>
            </ul>
            <h3>Right to restrict processing</h3>
            <p>You can ask us to stop or restrict processing your personal data. It is an alternative to requesting erasure of your data.</p>
            <p>This is not an absolute right and will only apply in certain circumstances such as:</p>
            <ul>
                <li>Where you are contesting the accuracy of your personal data and we are verifying the accuracy of the data.</li>
                <li>The data has been unlawfully processed (i.e. in breach of the lawfulness requirement)</li>
                <li>We no longer need the personal data but we need to keep it in order to establish, exercise or defend a legal claim.<br/>As a matter of good practice we will automatically restrict processing your personal data whilst we are considering its accuracy or the legitimate grounds for processing the personal data in question.</li>
            </ul>
            <p>When we restrict your personal data, we are still permitted to store it but not use it. We will inform all relevant third parties to confirm this right is carried out.</p>
            <p>You can make a request for restriction at any time and we have one month in which to respond to your request. This can be extended by two months where the request is complex or we receive a number of requests. We would advise you if an extension is necessary clearly stating our reasons why.</p>
            <h3>Right to data portability</h3>
            <p>The right to data portability allows individuals to obtain and reuse their personal data for their own purposes across different services. Before transferring your personal data we will carry out rigorous checks to verify your identity.</p>
            <p>The right to data portability applies:</p>
            <ul>
                <li>To personal data an individual has provided to a controller;</li>
                <li>Where the processing is based on the individual's consent or for the performance of a contract; and</li>
                <li>is carried out by automated means.</li>
            </ul>
            <p>If you request us to transfer your information we will do so in a format that is structured, commonly used and in a machine-readable form. We will respond to your request within one month. This can be extended by two months where the request is complex or we receive a number of requests. We would advise you if an extension is necessary clearly stating our reasons why.</p>
            <h3>Right to object</h3>
            <p>You have the right to object to processing based on legitimate interests or the performance of a task in the public interest/exercise of official authority (including profiling); direct marketing (including profiling); and processing for purposes of scientific/historical research and statistics.</p>
            <h3>Rights in relation to automated decision making and profiling</h3>
            <p>You have the right to object to automated decision making, to express your point of view, ask for human intervention, and to be provided with an explanation of the decision with your option to challenge it. The right is not absolute.</p>

            <h2>5. Further Information</h2>
            <h3>Notification of Breach</h3>
            <p>We make a promise as a company, that we will inform you at the first sign of a data breach. We want to make sure we are being completely transparent with you regarding your data and the security of your data. We will contact you via email or phone, to inform you of any suspicious activity.</p>
            <h3>Complaints</h3>
            <p>If you have any complaints about how we have used your personal data please contact our Data Protection Officer at <a href="mailto:compliance@galacticmarketing.co.uk">compliance@galacticmarketing.co.uk</a> or write to: The Data Protection Officer, Galactic Marketing Ltd, 49 Station Road, Polegate, England, BN26 6EA.</p>
            <p>We do recommend that you bring any issues to our attention as soon as possible. The sooner we know about the issue the sooner we can help resolve it.</p>
            <p>We will do our best to resolve your complaint but if you remain unsatisfied with any aspect relating to your personal information, you have the right to complain to the Information Commissioner's Office.</p>
            <p>
                The ICO may be contacted at <a href="https://ico.org.uk/make-a-complaint/" target="_blank" rel="noreferrer">https://ico.org.uk/make-a-complaint/</a><br/>
                By post at Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF<br/>
                Telephone: 0303 123 1113 (local rate) or 01625 545 745 if you prefer to use a national rate number.
            </p>
            <p>
                Alternatively, you may have the right to refer your complaint to the Financial Ombudsman Service who can be contacted at <a href="https://www.financial-ombudsman.org.uk/consumer/complaints.htm" target="_blank" rel="noreferrer">www.financial-ombudsman.org.uk/consumer/complaints.htm</a><br/>
                By post at Exchange Towner, London, E14 9SR<br/>
                Telephone: 0800 023 4567 (0300 123 9123 from a mobile)
            </p>
            <p>In relation to your complaint you can also request a review from the European Online Dispute Resolution platform: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">ec.europa.eu/consumers/odr/</a></p>
            <h3>Usage of links to other sites</h3>
            <p>This website may provide links to other websites which have privacy policies that differ from ours.  This Privacy Policy applies only to this website and how we process your personal data.</p>
            <p>We cannot be held responsible for the other sites Privacy Policy which will be specific to them. This also applies to site content, practices they carry out and the services and products they may offer; all of which we cannot take any liability for.</p>
            <h3>Cookies</h3>
            <p>When you visit this website a small text file known as a 'cookie' is placed on your computer or other device which may provide some personal data details from you. Some cookies are required for the functioning of the website, others allow us to recognise you each time you visit and remember your preferences. Cookies are also used to improve user experience on the website, advertising, tracking and browsing habits.  By continuing to use this website you agree to cookies being used. </p>
            <h3>Changes to this Privacy Policy</h3>
            <p>Galactic Marketing Ltd reserves the right to amend the content of this Privacy Policy at any time. If this occurs we will update policy and the revised policy will be posted on this website.  If you are using our services we would encourage you to visit this Privacy Policy on a regular basis to make sure you have read the latest version and understand what we do with your information.</p>
            <p>If you do not agree to the contents of this Privacy Policy and how we handle your personal data please do not continue to use the website.</p>
        </div>
    );

};

export default PrivacyPolicy;